import getSymbolFromCurrency from 'currency-symbol-map'
import VueApexCharts from 'vue-apexcharts'
import getEnv from '@/util/env'
import moment from 'moment'
import EventBus from '@/util/EventBus.js'
import { ExportForm } from '@/classes/ExportForm'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { Colors } from '@/classes/colors/Colors'
import BrowserCache from '@/services/browser-cache.js'
import StatsService from '@/services/stats.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import ShareButton from '@/components/Stats/ShareButton/ShareButton.vue'
import ShareModal from '@/components/Stats/ShareModal/ShareModal.vue'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import DeliveredMailing from '@/components/Stats/Mailing/DeliveredMailing.vue'
import UserInteractionMailing from '@/components/Stats/Mailing/UserInteractionMailing.vue'
import UnsubscribedMailing from '@/components/Stats/Mailing/UnsubscribedMailing.vue'
import Campaign from '@/models/Campaign'
import FormStats from '@/components/Stats/Forms/FormStats.vue'
import StatsMixin from '../../Mixins/StatsMixin'
import { MailingCalculatedData } from '@/classes/stats/Calculations/Mailing/MailingCalculatedData'
import { MailingGraphicData } from '@/classes/stats/MailingGraphicData'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import Pie from '@/components/Stats/Pie/Pie.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'CampaignMailingStats',
  components: {
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    OverlayManager,
    LandingPreview,
    ShareButton,
    ShareModal,
    ConfirmCustomFieldsModal,
    DeliveredMailing,
    UserInteractionMailing,
    UnsubscribedMailing,
    FormStats,
    StatCard,
    Pie,
    RadialBar,
    RadialBarWithLegend,
    SimpleBar,
    StackedMultiple,
    HeaderTopDashboard,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      mailingCalculatedData: new MailingCalculatedData(),
      deliveredRatioGraphic: null,

      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalSendings: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      additional: undefined,
      data: [],
      deliveredChart: {
        options: {},
      },
      ratioOpenChart: {
        options: {},
      },
      ratioClickThroughChart: {
        options: {},
      },
      ratioClickOpenChart: {
        options: {},
      },
      ratioBouncedChart: {
        options: {},
      },
      devicesChart: {
        options: {},
      },
      dataDevices: {
        totalOpened: 0,
        isMobile: 0,
      },
      unsubscribed: {
        percentage: '0',
        total: 0,
        deliveredTotal: 0,
        unsubscribedUnique: 0,
      },
      perPage: '5',
      exportForm: new ExportForm(),
      ready: true,
      campaign: new Campaign(),
      channel: null,
      template: null,
      landing: {},
      arrStatuses: Colors.statusesColors,
      barChartOptions: {
        title: {
          text: this.$t('Interaciones de usuario'),
        },
        series: [
          {
            name: this.$t('Enviados'),
            data: [],
          },
          {
            name: this.$t('Entregados'),
            data: [],
          },
          {
            name: this.$t('Abiertos'),
            data: [],
          },
          {
            name: this.$t('Clics'),
            data: [],
          },
          {
            name: 'Soft Bounces',
            data: [],
          },
          {
            name: 'Hard Bounces',
            data: [],
          },
          {
            name: this.$t('Bajas'),
            data: [],
          },
        ],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [Colors.colorChartSent, Colors.colorBarDelivered, Colors.colorBarOpened, Colors.colorBarClicked,
          Colors.colorBarSoftBounced, Colors.colorBarHardBounced, Colors.colorBarUnsubscribed],
        xaxis: {
          categories: [],
        },
      },
      selectedHeaders: [],
      sendingDetails: {
        initDate: '',
        endDate: moment().format('YYYY-MM-DD'),
        customFields: false,
        columns: [],
        events: [],
        sendingId: null,
      },
      campaginDataSource: {},
      readyStats: false,
      cards: [],
      openedRatioGraphicData: null,
      deliveredGraphicData: null,
      clicksRatioGraphicData: null,
      clickToOpenRatioGraphicData: null,
      undeliveredGraphicData: null,
      unsubscribedRatioBarChart: null,
      reBouncedRatioGraphicData: null,
      openedDevicesGraphicData: null,
      interactionItems: [],
      openedDevicesStats: null,
      loadedTableFirstTime: false,
      formIds: [],
    }
  },
  methods: {

    setGraphicsData () {
      const mailingGraphicData = new MailingGraphicData(this.mailingCalculatedData, this)
      this.cards = mailingGraphicData.getCardsData()
      this.deliveredRatioGraphic = mailingGraphicData.getDeliveredRatioGraphicGraphicData()
      this.openedRatioGraphicData = mailingGraphicData.getOpenedRatioGraphicData()
      this.deliveredGraphicData = mailingGraphicData.getDeliveredGraphicData()
      this.clicksRatioGraphicData = mailingGraphicData.getClicksRatioGraphicData()
      this.clickToOpenRatioGraphicData = mailingGraphicData.getClickToOpenRatioGraphicData()
      this.undeliveredGraphicData = mailingGraphicData.getUndeliveredGraphicData()
      this.unsubscribedRatioBarChart = mailingGraphicData.getUnsubscribedRatioBarChartGraphicData()
      this.reBouncedRatioGraphicData = mailingGraphicData.getReBouncedRatioGraphicData()
      this.openedDevicesGraphicData = mailingGraphicData.getOpenedDevicesGraphicData(this.openedDevicesStats)
    },


    downloadUndeliveredCsv () {
      const user = JSON.parse(localStorage.getItem('user'))
      const params = {
        sendingId: this.sendingDetails.sendingId,
        customFields: false,
        initDate: this.content.length ? this.content[0].campaign_date : moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        sendingEvents: [5, 8],
        columns: ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'subject', 'from', 'reply', 'event', 'countryIso', 'createdAt', 'externalId', 'Browser', 'OS', 'IP', 'URL'],
        token: user.token,
        hasInverseEvents: false,
      }
      const url = getEnv('backend_endpoint') + 'api/stats/mailing/sending-export-csv?' + new URLSearchParams(params).toString()
      window.location.href = url
    },


    prepareRemarketingFromClickedUrl (item) {
      const that = this
      EventBus.$emit('showLoading', true)
      StatsService.getContactsEmailsClickedUrl({ url: item.link_url, sendingId: this.sendingDetails.sendingId }).then((data) => {
        EventBus.$emit('showLoading', false)
        localStorage.setItem('contact-phones-new-campaign', JSON.stringify(data))
        that.$router.push('/campaign/mailing/create?type=basic')
      })
    },

    getPreview () {
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showPreviewFromStructure(this.template)
      })
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(5)
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getMailingCampaignDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalSendings = response.total
            if (this.totalSendings > 0) {
              this.setPieChart(this.additional[0])
              this.sendingDetails.initDate = moment(this.data[0].sending_date).format('YYYY-MM-DD')
              this.sendingDetails.sendingId = this.data[0].sending_id
            }
            setTimeout(() => {
              this.mailingCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            })
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },

    setTableData (data) {
      data.forEach(data => {
        data.undelivered_total = this.calculateUndeliveredTotal(data)
        this.barChartOptions.xaxis.categories.push(this.campaign.campaignName + '- Envío ' + data.sending_id)
      })
      this.content = data
      this.loadDeviceChart()
    },

    loadDeviceChart () {
      StatsService.getDeviceChartByCampaignIds({
        campaignIds: [this.campaign.campaignId],
      })
        .then(
          (response) => {
            this.dataDevices = response
            // devicesChart chart
            this.devicesChart.options = {
              legend: {
                show: false,
              },
              fill: {
                type: 'solid',
                colors: [Colors.colorBarDelivered, Colors.colorChartUniqueClicks],
              },
              labels: [this.$t('Dispositivo móvil').toString(), this.$t('Browser').toString()],
              colors: [Colors.colorBarDelivered, Colors.colorChartUniqueClicks],
            }
          },
          () => { },
        )
    },


    getParams () {
      const campaignId = this.$route.params.id
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        campaignId: campaignId,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.hard_bounced_unique) + parseInt(data.soft_bounced_unique) + parseInt(data.rejected_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculateUnopened (data) {
      return parseInt(data.delivered_total) - parseInt(data.opened_unique)
    },

    // eslint-disable-next-line max-lines-per-function
    setPieChart (totals) {
      if (totals === undefined) {
        return
      }

      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.unopened = this.calculateUnopened(totals)

      // Ratio opened chart
      this.ratioOpenChart.percentage = parseInt(totals.opened_unique) === 0 ? totals.opened_unique : ((totals.opened_unique / totals.delivered_total) * 100).toFixed(2)
      this.ratioOpenChart.series = [parseInt(totals.opened_unique), this.calculateUnopened(totals)]
      this.ratioOpenChart.dataInfo = [
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('Abiertos').toString(),
          value: totals.opened_unique,
        },
        {
          label: this.$t('No abiertos').toString(),
          value: this.calculateUnopened(totals),
        },
      ]
      this.ratioOpenChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartOpened, Colors.colorChartUnopened],
        },
        labels: [this.$t('Abiertos').toString(), this.$t('No abiertos').toString()],
      }

      // Ratio click through chart
      this.ratioClickThroughChart.percentage = parseInt(totals.delivered_total) === 0 ? totals.delivered_total : ((totals.clicked_unique / totals.delivered_total) * 100).toFixed(2)
      this.ratioClickThroughChart.series = [parseInt(totals.delivered_total), parseInt(totals.clicked_unique)]
      this.ratioClickThroughChart.dataInfo = [
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('Clics únicos').toString(),
          value: totals.clicked_unique,
        },
      ]
      this.ratioClickThroughChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorBarDelivered, Colors.colorChartUniqueClicks],
        },
        labels: [this.$t('Entregados').toString(), this.$t('Clics únicos').toString()],
      }

      // Ratio click open chart
      this.ratioClickOpenChart.percentage = parseInt(totals.opened_unique) === 0 ? totals.opened_unique : ((totals.clicked_unique / totals.opened_unique) * 100).toFixed(2)
      this.ratioClickOpenChart.series = [parseInt(totals.opened_unique), parseInt(totals.clicked_unique)]
      this.ratioClickOpenChart.dataInfo = [
        {
          label: this.$t('Abiertos').toString(),
          value: totals.opened_unique,
        },
        {
          label: this.$t('Clics únicos').toString(),
          value: totals.clicked_unique,
        },
        {
          label: this.$t('Abiertos únicos').toString(),
          value: totals.opened_unique,
        },
      ]
      this.ratioClickOpenChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartTotalClicks, Colors.colorChartUniqueClicks],
        },
        labels: [this.$t('Abiertos únicos').toString(), this.$t('Clics únicos').toString()],
      }

      // Ratio bounced chart
      this.ratioBouncedChart.percentage = parseInt(totals.opened_unique) === 0 ? totals.opened_unique : ((totals.clicked_unique / totals.opened_unique) * 100).toFixed(2)
      this.ratioBouncedChart.series = [parseInt(totals.delivered_total), parseInt(totals.hard_bounced_unique), parseInt(totals.soft_bounced_unique)]
      this.ratioBouncedChart.dataInfo = [
        {
          label: this.$t('Enviados').toString(),
          value: totals.sent_total,
        },
        {
          label: this.$t('No Entregados').toString(),
          value: totals.undelivered_total,
        },
        {
          label: 'Hard Bounced',
          value: totals.hard_bounced_unique,
        },
        {
          label: 'Soft Bounced',
          value: totals.soft_bounced_unique,
        },
      ]
      this.ratioBouncedChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartDelivered, Colors.colorChartHardBounced, Colors.colorChartSoftBounced],
        },
        labels: [this.$t('Enviados').toString(), this.$t('No Entregados').toString(), 'Hard Bounced', 'Soft Bounced'],
      }
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      const url = getEnv('backend_endpoint') + 'api/stats/mailing/campaign-export-' + urlSuffix + '?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.setFromParamsAndColumns(this.getParams(), this.getColumns())).toString()
    },

    setFromParamsAndColumns (params, columns) {
      const data = {}
      data.campaignId = this.campaign.id
      data.columns = JSON.stringify(columns)
      data.searchTerm = params.searchTerm
      data.sort = params.sortBy ? params.sortBy : ''
      const user = JSON.parse(localStorage.getItem('user'))
      data.token = user.token
      return data
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    showOverlayPreview () {
      EventBus.$emit('showOverlayPreview')
    },

    selectedColumn (column) {
      let exist = false
      for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
        if (!exist) {
          exist = this.selectedHeaders[i].value === column
        }
      }
      return exist
    },

    getItemTotalsParsedObject (item) {
      return {
        7: parseInt(item.sent_total),
        1: parseInt(item.delivered_total),
        92: parseInt(item.undelivered_total),
        2: parseInt(item.opened_unique),
        3: parseInt(item.clicked_unique),
        5: parseInt(item.hard_bounced_unique),
        8: parseInt(item.soft_bounced_unique),
        4: parseInt(item.unsubscribed_unique),
        93: parseInt(item.delivered_total) - parseInt(item.opened_unique),
        91: parseInt(item.delivered_total) - parseInt(item.clicked_total) - parseInt(item.expired_total),
      }
    },

    showConfirmCustomFields (actionMode, item) {
      const totalEventValues = this.getItemTotalsParsedObject(item)
      EventBus.$emit('showConfirmCustomFields', actionMode, totalEventValues)
    },

    statusColor (campaign) {
      return this.arrStatuses[campaign.status_name]?.color
    },
    downloadFormAnswers () {
      EventBus.$emit('downloadFormAnswersEvent')
    },
    setFormIds () {
      const formIds = []
      // const matches = this.channel.message.match(/({FORM_[0-9]+})/g)
      // if (matches) {
      //   matches.forEach(match => {
      //     formIds.push(match.match(/([0-9]+)/g))
      //   })
      // }
      if (this.channel.template_render_web) {
        const matchesLanding = this.channel.template_render_web.match(/(\[FORM_[0-9]+\])/g)
        if (matchesLanding) {
          matchesLanding.forEach(match => {
            formIds.push(match.match(/([0-9]+)/g))
          })
        }
      }
      this.formIds = formIds
    },
  },
  computed: {
    interactionHeaders () {
      return [
        {
          text: this.$t('Texto del enlace'),
          value: 'link_text',
        },
        {
          text: this.$t('Url del enlace'),
          value: 'link_url',
        },
        {
          text: this.$t('Clics'),
          value: 'clics',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
        },
      ]
    },
    headers () {
      return [
        {
          text: this.$t('ID Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Fecha'),
          value: 'sending_date',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Recibidos'),
          value: 'delivered_total',
        },
        {
          text: this.$t('No Recibidos'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Abiertos'),
          value: 'opened_unique',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_unique',
        },
        {
          text: 'Hard Bounces',
          value: 'hard_bounced_unique',
        },
        {
          text: 'Soft Bounces',
          value: 'soft_bounced_unique',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
          sortable: false,
          width: '14%',
        },
      ]
    },
    selectHeaders () {
      return [
        {
          text: this.$t('ID Envío'),
          value: 'sending_id',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Recibidos'),
          value: 'delivered_total',
        },
        {
          text: this.$t('No Recibidos'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Abiertos'),
          value: 'opened_unique',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_unique',
        },
        {
          text: 'Hard Bounces',
          value: 'hard_bounced_unique',
        },
        {
          text: 'Soft Bounces',
          value: 'soft_bounced_unique',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
        },
      ]
    },
    showHeaders () {
      return this.headers.filter(h => this.selectedHeaders.map(sh => sh.value).includes(h.value))
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    params: {
      handler () {
        if (this.loadedTableFirstTime) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
    locale () {
      if (this.loadedTableFirstTime) {
        this.setGraphicsData()
      }
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
  created () {
    EventBus.$emit('showLoading', true)
    this.selectedHeaders = this.headers
    const campaignId = this.$route.params.id
    StatsService.getMailingCampaignData(campaignId)
      .then(
        (response) => {
          console.log('response', response)
          this.campaign = new Campaign()
          this.campaign.load(response.campaign)
          this.campaign.campaignName = response.campaign.campaignName
          this.channel = response.channel
          this.template = JSON.parse(response.template)
          this.landing = response.landing
          if (this.landing) {
            this.landing.structure = this.landing.structure || this.template
          }
          this.interactionItems = response.clickStats
          this.openedDevicesStats = response.openedDevicesStats
          setTimeout(() => {
            this.getDataFromApi()
            this.loadedTableFirstTime = true
            this.setFormIds()
          })
        },
        (err) => {
          EventBus.$emit('showAlert', 'warning', this.$t(err.response.data.message))
          EventBus.$emit('showLoading', false)
          this.$router.push({ name: 'ChannelMailingStats' })
        },
      )
      .finally(() => {
        this.loading = false
      })
    if (!this.$isSectionsBlockeds('costs')) {
      this.headers.splice(11, 0, {
        text: this.$t('Coste'),
        align: 'start',
        value: 'cost',
        class: 'w-6',
      })
    }

    EventBus.$on('changedLanguage', () => {
      this.readyStats = false
      setTimeout(() => {
        this.setGraphicsData()
        this.readyStats = true
      })
    })
  },
}
